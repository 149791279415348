import CircularIcon from '../../../Components/Icons/CircularIcon';
import { FiFileText, FiMessageSquare } from 'react-icons/fi';
import Styles from '../Editor.module.scss';
import OptionsDropDown from 'Components/OptionsDropDown/OptionsDropDown';
import { FaEllipsisH } from 'react-icons/fa';
import BSButton from 'Components/Button/BSButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Star from 'Components/Star/Star';
import useStarDocument from 'hooks/documents/useStarDocument';
import useDeleteStarredDocument from 'hooks/documents/useDeleteStarredDocument';
import { useSelector } from 'react-redux';
import StyleClasses from 'Components/Projects/Projects.module.scss';
import ShareModal from '../../Modal/ShareModal/ShareModal';
import ProjectIntegrationsModal from '../../Modal/ProjectIntegrationsModal/ProjectIntegrationsModal';
import { debounce } from 'lodash';
import { updateDocument } from 'services/documentService';
import { ROUTES } from 'constants/routes';
import VerticalSeparator from 'Components/Separator/VerticalSeparator';
import { IoArrowBack } from 'react-icons/io5';
import Select from 'react-select';
import { FiZap } from 'react-icons/fi';
import { StatusOptions } from 'Components/Status/StatusOption';
import { LuUserPlus } from 'react-icons/lu';
import ButtonGroupComponent from 'Components/ButtonGroupComponnet/ButtonGroupComponent';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const Header = ({
  document,
  contentSidebarCollapse,
  refetchContent,
  setShowComments,
  showComments,
  isComment,
  comments,
  canDelete,
  collaborator,
  collaborators,
  setShowBriefDrawer,
  setShowTemplateDrawer,
  shouldShowContentSettings,
  freeStyle
}) => {
  const [title, setTitle] = useState(document?.title || '');
  const [saveAsModalView, setSaveAsModalView] = useState(false);
  const [publishModalView, setPublishModalView] = useState(false);
  const [shareModalView, setShareModalView] = useState(false);
  const [star, setStar] = useState(document?.starred || {});
  const [checkedComments, setCheckedComments] = useState(false);
  const defaultStatus = StatusOptions.find(
    (option) => option.value === 'in_progress',
  );
  const [selectedOption, setSelectedOption] = useState(
    document.status || defaultStatus,
  );
  const createByBrief = document.task ? true : false;

  const { user } = useSelector((state) => state.auth);

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const { documentId } = useParams() || {};
  const navigate = useNavigate();
  const { mutateAsync: addStar } = useStarDocument();
  const { mutateAsync: removeStar } = useDeleteStarredDocument();

  useEffect(() => {
    if (document) {
      setStar(document.starred);
    }
  }, [document]);

  const updateStatusWithDebounce = useCallback(
    debounce(async (newStatus) => {
      await updateDocument(document.id, { status: newStatus });
    }, 400),
    [updateDocument, document.id],
  );

  const handleChange = (selectedOption) => {
    updateStatusWithDebounce(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  const shareModalViewHandler = () => {
    if(currentSubscription?.plan_name === "Basic Plan" && document?.project_id !== null){
      setShowUpgradeModal(true);
    }else{
      setShareModalView(!shareModalView);
    }
    
  };

  const handleTitleChange = async ({ target: { value } }) => {
    updateTitleWithDebounce(value);
    setTitle(value);
  };

  const updateTitleWithDebounce = useCallback(
    debounce(async (newTitle) => {
      await updateDocument(documentId, { title: newTitle });
    }, 400),
    [updateDocument],
  );

  const handleStar = async () => {
    if (Object.keys(star || {}).length) {
      await removeStar(star.id);
    } else {
      await addStar(documentId);
    }

    await refetchContent(false);
  };

  const totalComments = useMemo(() => {
    return Object.keys(comments).reduce((acc, commentKey) => {
      const commentThread = comments[commentKey][0];
      if (
        Number(commentThread.user_id) !== Number(user?.id) &&
        commentThread.status !== 'resolved'
      ) {
        return acc + 1;
      }

      return acc;
    }, 0);
  }, [comments]);

  const handleCloseBtn = (e) => {
    e.preventDefault();
    if (document.template_id) {
      navigate(ROUTES.Library);
      return;
    }
    navigate(-1);
  };
  const SelectStyles = {
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? '#e5e5e5'
          : isFocused
            ? 'lightgrey'
            : '#fff',
        background: isSelected ? `url("./Images/tickDark.svg")` : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '500',
        color: '#344054',
        height: '100%',
        padding: '4px 12px',
        cursor: 'pointer',
      };
    },
    control: (baseStyles) => {
      return {
        ...baseStyles,
        cursor: 'pointer',
      };
    },
  };
  return (
    <>
      <div
        style={{
          backgroundColor: '',
          padding: '8px 24px',
          borderBottom: '1px solid #EAECF0',
        }}
        className="d-flex justify-content-between align-items-center"
      >
        <div
          className="d-flex gap-2 w-100"
          style={{ paddingRight: '8px', cursor: 'pointer' }}
        >
          <CircularIcon width="32px" height="32px">
            <IoArrowBack onClick={() => navigate(-1)} color="#101828" size="20px" />
          </CircularIcon>
          <CircularIcon background="#F2FBFB" width="32px" height="32px">
            <FiFileText color="#00A7B7" size="20px" />
          </CircularIcon>
          <div className={Styles.documentStatusWrapper}>
            <input
              className={Styles.textInput}
              value={title}
              onChange={handleTitleChange}
              style={{ backgroundColor: 'transparent' }}
              disabled={isComment}
            />
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ gap: '8px' }}
        >
          <div className="d-flex gap-3 align-items-center">
            <Select
              isSearchable={false}
              classNamePrefix="headerSelect"
              className="headerSelect"
              styles={SelectStyles}
              options={StatusOptions}
              defaultValue={defaultStatus}
              value={StatusOptions.find(
                (option) => option.value === selectedOption,
              )}
              onChange={(selectedOption) => handleChange(selectedOption)}
            />
            {/* <StatusSelect /> */}
            {/* <CollaboratorsAvatar collaborators={collaborators} /> */}

            {/* <AvatarGroup
              avatars={['https://i.pravatar.cc/300', 'https://i.pravatar.cc']}
            /> */}
            <div
              className={StyleClasses.ProjectDetail_titleContainer_editorAdd}
              onClick={shareModalViewHandler}
            >
              <LuUserPlus color="#344054" />
            </div>
          </div>

          <div className="mx-2">
            <VerticalSeparator />
          </div>
          <div style={{ padding: '10px' }}>
            <Star
              isStarred={Object.keys(star || {})?.length}
              shouldDisplay={true}
              onClick={handleStar}
            />
          </div>
          <div
            style={{ position: 'relative', cursor: 'pointer', padding: '10px' }}
          >
            {!!totalComments && !checkedComments && (
              <span className={Styles.commentBadge}>{totalComments}</span>
            )}
            <FiMessageSquare
              size={20}
              fill={showComments ? '#344054' : 'none'}
              onClick={() => {
                setShowComments('comments');
                !checkedComments && setCheckedComments(true);
              }}
            />
          </div>

          {/* {shouldShowContentSettings ? (
            <BSButton
              onClick={() => setShowTemplateDrawer(true)}
              ButtonText="Template"
              variant="light"
              BtnWrapperClass="h-100"              
              icon={<FiZap />}
              classList={'button text-nowrap h-100 cancel_btn'}

            />
          ) : (
            <BSButton
              classList={'button text-nowrap h-100 cancel_btn'}
              ButtonText="Edit Brief"
              variant="light"
              BtnWrapperClass="h-100"
              disabled={false}
              onClick={() => setShowBriefDrawer(true)}
            />
          )} */}

          {shouldShowContentSettings ? (
            <BSButton
              onClick={() => setShowTemplateDrawer(true)}
              ButtonText="Template"
              variant="light"
              BtnWrapperClass="h-100"
              icon={<FiZap />}
              classList={'button text-nowrap h-100 cancel_btn'}
            />
          ) : createByBrief ? (
            <BSButton
              classList={'button text-nowrap h-100 cancel_btn'}
              ButtonText="Edit Brief"
              variant="light"
              BtnWrapperClass="h-100"
              disabled={false}
              onClick={currentSubscription?.plan_name === "Basic Plan" && document?.project_id !== null ? ()=>setShowUpgradeModal(true) :() => setShowBriefDrawer(true)}
            />
          ) : null}

          {!isComment && (
            <ButtonGroupComponent setPublishModalView={setPublishModalView} />
          )}
          {!isComment && (
            <div style={{ padding: '10px' }}>
              <OptionsDropDown
                OptionsFor={'EditorDocument'}
                drop="start"
                Icon={<FaEllipsisH fill="#344054" size={20} />}
                contentSidebarCollapse={contentSidebarCollapse}
                document={document}
                refetchContent={refetchContent}
                canDelete={canDelete}
                collaborator={collaborator}
                saveAsModalView={saveAsModalView}
                setSaveAsModalView={setSaveAsModalView}
              />
            </div>
          )}

          {/* <Link className={Styles.closeButton} onClick={handleCloseBtn}>
            Close
          </Link> */}
        </div>
      </div>

      {shareModalView && (
        <ShareModal
          show={shareModalView}
          onHide={() => {
            setShareModalView(false);
          }}
        />
      )}

      {publishModalView && (
        <ProjectIntegrationsModal
          show={publishModalView}
          onHide={() => {
            setPublishModalView(false);
          }}
          projectId={document.project_id}
          documentId={document.id}
        />
      )}
       {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={"Updating campaign related content is a pro feature. Please update to pro plan to access this feature."}

        />
      )}
    </>
  );
};

export default Header;
