// use the commented code to remove coming soon

// import React, { useState, useEffect, useRef } from 'react';
// import { Button, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
// import { RiArrowDownSLine } from 'react-icons/ri';
// import { TbClockHour4 } from 'react-icons/tb';
// import Styles from './ButtonGroupComponent.module.scss';
// import ScheduleForm from 'Containers/Editor/Components/ScheduleForm/ScheduleForm';
// import { useSelector } from 'react-redux';

// function ButtonGroupComponent() {
//   const [openModal, setOpenModal] = useState(false);
//   const modalRef = useRef(null);

//   const { user } = useSelector((state) => state.auth);
//   const { subscriptions } = user || {};
//   const { current_subscription: currentSubscription } = subscriptions ?? {};

//   const handleOpenModal = () => {
//     setOpenModal(!openModal);
//   };

//   const handleClickOutside = (event) => {
//     if (modalRef.current && !modalRef.current.contains(event.target)) {
//       setOpenModal(false);
//     }
//   };

//   const planName = currentSubscription?.plan_name;

//   useEffect(() => {
//     if (openModal) {
//       document.addEventListener('mousedown', handleClickOutside);
//     } else {
//       document.removeEventListener('mousedown', handleClickOutside);
//     }

//     return () => {
//       document.removeEventListener('mousedown', handleClickOutside);
//     };
//   }, [openModal]);

//   return (
//     <>
//       {planName === 'Pro Plan' && (
//         <div className={Styles.modal_container}>
//           <ButtonGroup className="d-flex gap-1">
//             <Button className={`secondary_btn ${Styles.button}`}>
//               Publish
//             </Button>

//             <DropdownButton
//               as={ButtonGroup}
//               title={<RiArrowDownSLine />}
//               id="bg-nested-dropdown"
//               className={`secondary_btn ${Styles.dropbtn}`}
//             >
//               <Dropdown.Item
//                 eventKey="1"
//                 className={Styles.option}
//                 onClick={handleOpenModal}
//               >
//                 <TbClockHour4 />
//                 <span>Schedule</span>
//               </Dropdown.Item>
//             </DropdownButton>
//           </ButtonGroup>
//           {openModal && (
//             <div ref={modalRef} className={Styles.modal_class}>
//               <ScheduleForm />
//             </div>
//           )}
//         </div>
//       )}
//     </>
//   );
// }

// export default ButtonGroupComponent;

//code to display comming soon

import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  DropdownButton,
  Dropdown,
  ButtonGroup,
  Tooltip,
  Overlay,
} from 'react-bootstrap';
import { RiArrowDownSLine } from 'react-icons/ri';
import { TbClockHour4 } from 'react-icons/tb';
import Styles from './ButtonGroupComponent.module.scss';
import ScheduleForm from 'Containers/Editor/Components/ScheduleForm/ScheduleForm';
import { useSelector } from 'react-redux';

function ButtonGroupComponent({setPublishModalView}) {
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);

  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  // Tooltip state
  const [showTooltip, setShowTooltip] = useState(false);
  const [showScheduleTooltip, setShowScheduleTooltip] = useState(false);

  const publishButtonRef = useRef(null);
  const scheduleItemRef = useRef(null);

  const handlePublishMouseEnter = () => setShowTooltip(true);
  const handlePublishMouseLeave = () => setShowTooltip(false);

  const handleScheduleMouseEnter = () => setShowScheduleTooltip(true);
  const handleScheduleMouseLeave = () => setShowScheduleTooltip(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  const planName = currentSubscription?.plan_name;

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  return (
    <>
      <div className={Styles.modal_container}>
        <ButtonGroup className="d-flex gap-1">
          <Button
            ref={publishButtonRef}
            className={`secondary_btn ${Styles.button}`}
            // onMouseEnter={handlePublishMouseEnter}
            // onMouseLeave={handlePublishMouseLeave}
            onClick={()=>setPublishModalView(true)}
          >
            Publish
          </Button>
          <Overlay
            target={publishButtonRef.current}
            show={showTooltip}
            placement="top"
          >
            {(props) => (
              <Tooltip id="publish-tooltip" {...props}>
                Coming Soon
              </Tooltip>
            )}
          </Overlay>

          <DropdownButton
            as={ButtonGroup}
            title={<RiArrowDownSLine />}
            id="bg-nested-dropdown"
            className={`secondary_btn ${Styles.dropbtn} p-0`}
          >
            <Dropdown.Item
              eventKey="1"
              className={Styles.option}
              // onClick={handleOpenModal}
              ref={scheduleItemRef}
              onMouseEnter={handleScheduleMouseEnter}
              onMouseLeave={handleScheduleMouseLeave}
            >
              <TbClockHour4 />
              <span>Schedule</span>
            </Dropdown.Item>
            <Overlay
              target={scheduleItemRef.current}
              show={showScheduleTooltip}
              placement="top"
            >
              {(props) => (
                <Tooltip id="schedule-tooltip" {...props}>
                  Coming Soon
                </Tooltip>
              )}
            </Overlay>
          </DropdownButton>
        </ButtonGroup>
        {openModal && (
          <div ref={modalRef} className={Styles.modal_class}>
            <ScheduleForm />
          </div>
        )}
      </div>
    </>
  );
}

export default ButtonGroupComponent;
